import React, { forwardRef} from 'react';
import { SignUpForm } from '../../../Components/SignUpForm';


type FirstSectionProps = {
  
}
export const BottomSection: React.FC<FirstSectionProps & React.RefAttributes<HTMLDivElement>> = forwardRef<HTMLDivElement>((props, ref) => {

  return (
    <div className='bg-primary-700 p-4'>
      <div className="container mx-auto p-5">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
          <h2>
            Let's get your office talking again.
          </h2>
          {/* Sign-up Form */}
            <SignUpForm isInModal={false}/>
        </div>
      </div>
      
    </div>
  );
});
import React, { forwardRef, LegacyRef, MutableRefObject, useRef } from 'react';
import { ReactComponent as LogoSVG } from './../../../Assets/logoFull.svg';
// import { Button, TextField, Checkbox, Form } from 'react-aria-components';
import { Button, Form, TextField, Input, Label } from "../Components/Base/"
import { useForm, ValidationError } from '@formspree/react';


type SignUpFormProps = {
  isInModal: boolean;
}
export const SignUpForm = forwardRef<HTMLDivElement, SignUpFormProps>((props, ref) => {
  const [state, handleSubmit] = useForm("xeqynvqq");
  const { isInModal }  = props;
  return (
    <div  className="bg-tertiary-50 border-2 rounded-xl shadow-md p-10 grid gap-8 max-w-[475px] justify-center text-black">
        {state?.succeeded 
        ?  
        <h2> Thanks for reaching out. <br/> <br/> ✉️ 🎉 <br/> <br/> We promise to get back to you within 24 hours!</h2>
        : 
        (<><h2 className='text-3xl md:text-4xl text-center'> Sign up for a live demo</h2>
        <Form className='gap-4 justify-center' onSubmit={handleSubmit}> 
            <TextField autoFocus={isInModal} label='What is your name?' id="name" name="name" type='text'/>
            <TextField label='Best phone number to reach you?' id="phone" name="phone" type='tel' isRequired errorMessage="We need your number" description="We won't send unwanted texts or calls!"/>
            <TextField label='What is the name of your clinic?' id="clinic name" name="clinic name" type='text'/>
            <TextField label='Where are you located?' id="location" name="location" type='text'/>
            <Button type='submit' isDisabled={state.submitting} variant='primary' className={"mt-6"}>Submit</Button>
        </Form></>)
        }
        </div>

  );
});
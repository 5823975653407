import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Header, SectionOne, SectionTwo, SectionThree, SectionFour, BottomSection, Footer } from "./Sections";

export const LandingPage: React.FC = () => {


  return (
    <div>
      {<Header classNames="h-16" />}
      <div className='min-height pt-16 overflow-hidden relative flex flex-col z-10 bg-primary-50' style={{backgroundColor: "#f8f7ea"}}>
        <SectionOne />
        <SectionThree/>
        <SectionTwo />
        <BottomSection  />
      </div>
      <Footer />
    </div>
  );
};

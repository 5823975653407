import React, { useMemo } from 'react';
import { composeRenderProps, Button as RACButton, ButtonProps as RACButtonProps } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { focusRing } from './utils';

export interface ButtonProps extends RACButtonProps {
  variant?: 'primary' | 'secondary' | 'destructive' | 'icon'
}

let button = tv({
  extend: focusRing,
  base: 'px-6 text-center text-sm	h-10 m-2.5 rounded-full block border-2 border-black font-semibold shadow-md shadow-amber-500 cursor-pointer select-none touch-manipulation hover:bg-right hover:no-underline active:bg-right hover:no-underline active:no-underline active:scale-95',
  variants: {
    variant: {
      primary: 'transition-all duration-500 ', // define primary variant styles here
      secondary: 'transition-all duration-500 ', // define secondary variant styles here
      destructive: '...', // define destructive variant styles here
      icon: '...' // define icon variant styles here
    },
    isDisabled: {
      true: 'opacity-50 cursor-not-allowed'
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

const primaryStyles = {
  backgroundImage: "linear-gradient(45deg, #739e45 0%, #a5cb70  51%, #608a3c  100%)",
  backgroundSize: '180% auto',
}
const secondaryStyles = {
  backgroundImage: "linear-gradient(45deg, #790606 0%, #b55c59  51%, #d7321e  100%)",
  color: 'black',
  textColor: 'black',
  backgroundSize: '170% auto',
}

const destructiveStyles = {
  backgroundImage: "linear-gradient(45deg, #739e45 0%, #B3EA67  51%, #608a3c  100%)",
  backgroundSize: '180% auto',
  color: "white",
  animation: 'move-background 3s ease infinite', // Added animation for the gradient background
}

export function Button(props: ButtonProps) {
  const style = useMemo(() => 
  props.variant === "primary" ? primaryStyles : props.variant === "secondary" ? secondaryStyles : destructiveStyles,
   [primaryStyles, secondaryStyles, destructiveStyles, props.variant]);
  
  return (
    <RACButton
    style={style}
      {...props}
      className={composeRenderProps(
        props.className,
        (className, renderProps) => button({
          ...renderProps, 
          variant: props.variant, 
          className
        })
      )} />
  );
  
}

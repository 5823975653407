import React from 'react';
import { Dialog, ModalOverlay, ModalOverlayProps, Modal as RACModal } from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Button } from './Button';
import { IoClose } from "react-icons/io5";

type Props = ModalOverlayProps & {
};


const overlayStyles = tv({
  base: 'fixed top-0 left-0 w-full h-[--visual-viewport-height] isolate z-50 bg-black/[15%] flex items-center justify-center p-4 text-center backdrop-blur-lg',
  variants: {
    isEntering: {
      true: 'animate-in fade-in duration-200 ease-out'
    },
    isExiting: {
      true: 'animate-out fade-out duration-200 ease-in'
    }
  }
});

const modalStyles = tv({
  base: 'rounded-2xl bg-white dark:bg-zinc-800/70 dark:backdrop-blur-2xl dark:backdrop-saturate-200 forced-colors:bg-[Canvas] text-left align-middle text-slate-700 dark:text-zinc-300 shadow-2xl bg-clip-padding border border-black/10 dark:border-white/10',
  variants: {
    isEntering: {
      true: 'animate-in zoom-in-105 ease-out duration-200'
    },
    isExiting: {
      true: 'animate-out zoom-out-95 ease-in duration-200'
    }
  }
});

export function Modal({ children, ...props }: Props) {
    // Handler to close when clicking the overlay backdrop.

  return (
    <ModalOverlay {...props} className={overlayStyles}>

      <RACModal isDismissable {...props} className={modalStyles} />
          <Dialog>
            <>
            <Button className="relative w-1 top-8 left-[85%] right round" variant="secondary" slot="close">
              
              <IoClose className="relative right-[.7em] h-6 w-6" />

              </Button>

               {children}
            </>
          </Dialog>

      <RACModal/>

    </ModalOverlay>
  );
}

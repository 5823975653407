import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as LogoSVG } from './../../../Assets/logoFull.svg'; 
import { cn } from '../../../Utils';
import { Button } from '../../../Components';

type SectionThreeProps = {}

export const SectionThree: React.FC<SectionThreeProps> = (props) => {

  return (
    <div className='bg-primary-500 p-4'>
      <div className="container mx-auto p-5">
        <div className="my-8">
            <div className="relative" style={{ paddingBottom: '56.25%', height: 0 }}>
              {/* <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://youtu.be/BQu2m4LI084"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              /> */}
              <iframe className='absolute top-0 left-0 w-full h-full' src="https://www.youtube.com/embed/BQu2m4LI084?si=IYXZ5F0wIbarI9-r" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
            </div>
          </div>
      </div>
    </div>
  );
};
